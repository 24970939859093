<template>
  <v-dialog
    :value="showForm"
    @input="(v) => v || HIDE_FORM()"
    max-width="600px"
    persistent
  >
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{
          editMode ? " Edit Offers" : " Add Offers"
        }}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  hide-details
                  clearable
                  label="القسم"
                  prepend-icon="mdi-sitemap"
                  class="mb-5"
                  item-text="section"
                  item-value="id"
                  v-model="form.section_id"
                  :error-messages="errors.section_id"
                  :items="sections"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  hide-details
                  clearable
                  label="البلد"
                  prepend-icon="mdi-sitemap"
                  class="mb-5"
                  item-text="country"
                  item-value="id"
                  v-model="form.country_id"
                  :error-messages="errors.country_id"
                  :items="countries"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <label>من تاريخ</label>
                <input
                  type="datetime-local"
                  v-model="form.start_date"
                  :error-messages="errors.start_date"
                  required
                />
              </v-col>
              <v-col cols="12">
                <label>الى تاريخ</label>
                <input
                  type="datetime-local"
                  v-model="form.end_date"
                  :error-messages="errors.end_date"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  dense
                  outlined
                  label="image"
                  prepend-icon="mdi-image"
                  accept="image/*"
                  v-model="form.image"
                  :error-messages="errors.image"
                ></v-file-input>
                <p class="error_class">*accept JPG SIZES 1200*1600</p>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.whatsapp"
                  label="واتساب"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.phone"
                  label="الهاتف"
                  outlined
                ></v-text-field>
                <p>* ادخل رقم الهاتف مسبوق بكود الدولة</p>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.title"
                  label="عنوان الاشعار"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  rows="4"
                  prepend-icon="mdi-pen"
                  label="وصف الاشعار"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions class="py-3">
        <v-spacer></v-spacer>
        <v-btn
          :loading="formLoading"
          color="secondary"
          elevation="1"
          dark
          @click="editMode ? update(form) : create(form)"
          >حفظ</v-btn
        >
        <v-btn @click="HIDE_FORM()">
          <v-icon>mdi-close</v-icon>
          إلغاء
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations ,mapActions} from "vuex";

export default {
  name: "record-form",

  data: function () {
    return {
      form: {},
      errors: {},
      formLoading: false,
      sections: [],
      countries: [],
    };
  },
  created() {
      this.fetchCountries();
      this.fetchSections();
  },
  computed: {
    ...mapState("offers", {
      editMode: (state) => state.editMode,
      showForm: (state) => state.showForm,
      formRecord: (state) => {
        return {
          id: state.formRecord.id,
          start_date: state.formRecord.start_date,
          end_date: state.formRecord.end_date,
          country_id: state.formRecord.country_id,
          section_id: state.formRecord.section_id,
          title: state.formRecord.title,
          phone: state.formRecord.phone,
          whatsApp: state.formRecord.whatsApp,
          description: state.formRecord.description,
          // icon: state.formRecord.icon
        };
      },
    }),
  },

  watch: {
    formRecord(form) {
      this.form = form;
    },
  },

  methods: {
    ...mapActions("offers",["fetchRecords"]),
    ...mapMutations("offers", ["HIDE_FORM"]),
    fetchSections() {
      this.axios.get("/admin/sliders/section_dropdown").then((response) => {
        this.sections = response.data;
      });
    },

    fetchCountries() {
      this.axios.get("/admin/sliders/countries_dropdown").then((response) => {
        this.countries = response.data;
      });
    },
    create(form) {
      this.formLoading = true;
      this.$store
        .dispatch("offers/create", { form })
        .then((response) => {
          this.$bus.$emit("showSnackbar", {
            text: "تمت اضافة التصنيف بنجاح",
            color: "success",
          });
          this.HIDE_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          for (let key in error.response.data.errors) {
            this.$bus.$emit("showSnackbar", {
              text: error.response.data.errors[key][0],
              color: "error",
            });
          }
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    },
   
    update(form) {
      this.formLoading = true;
      // console.log({ form });
      this.$store
        .dispatch("offers/update", { form })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
              text: 'نجحت العمليه',
              color: "success",
            });
        })
        .catch((error) => {
          for (let key in error.response.data.errors) {
            this.$bus.$emit("showSnackbar", {
              text: error.response.data.errors[key][0],
              color: "error",
            });
          }
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    },
  },
};
</script>
